import React from "react";

import "../styles/footerStyle.css"


function Footer() {

    return (
        <>
            <footer>
                <h3 id="footer">Brain Steel - Since 2020</h3>                
            </footer>
        </>
    )
}

export default Footer;