import React from "react";

import Header from "./Header"

const About = () => {

    return (

        <>
            <Header />
        </>
    )
}

export default About