import React from "react";

import { MainRoutes } from "./routes/MainRoutes";

function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default App;
